@import '../../../palette.scss';

.customRadio {
  accent-color: $secondary-main;
  margin-right: 10px;
}
.surveyBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    @extend .neomorphic_btn;
    color: #fff;
    padding: 8px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 20px;
  }
}
