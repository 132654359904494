@import '../../../palette.scss';

@mixin textUnderline($width: 50px) {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 2px;
    width: $width;
    background-color: $secondary-main;
    border-radius: 2px;
  }
}

#tsparticles {
  width: 100%;
  height: 100%;
}

.scrollToTop {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #232537;
  @extend .neomorphic_btn;
  img {
    height: 16px;
  }
}

.homeContainer {
  overflow: hidden;
  position: relative;
  margin-top: -150px;
  .floatingEle1 {
    position: absolute;
    width: 220px;
    top: -150px;
    @media (max-width: 411px) {
      width: 120px;
    }
  }
  .floatingEle2 {
    position: absolute;
    width: 200px;
    bottom: -80px;
    right: 40px;
    transform: rotate(180deg);
    @media (max-width: 411px) {
      width: 120px;
    }
  }

  // Section 1
  .heroContainer {
    height: 100vh;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1050px) {
      align-items: flex-start;
      
    }

    .heroContentContainer {
      width: 50%;
      height: 50vh;
      margin-top: 80px;
      @media (max-width: 1050px) {
        width: 100%;
        height: 75vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
       
      }
      .mainTitleText {
        position: relative;

        .backText {
          position: absolute;
          color: $dark-text;
          font-size: 12rem;
          font-weight: 800;
          text-align: center;
          opacity: 0.18;
          text-shadow: 0px -4px 4px #000000b8;
          top: -40%;
          left: 50%;
          transform: translateX(-50%);
          z-index: -10;
          line-height: 1;
          @media (max-width: 1050px) {
            font-size: 8rem;
          }
          @media (max-width: 411px) {
            font-size: 6rem;
          }
        }

        .taglineText {
          position: relative;
          color: $white;
          font-size: 9rem;
          font-weight: 800;
          text-align: center;
          line-height: 1;
          @media (max-width: 1050px) {
            font-size: 6rem;
          }
          @media (max-width: 411px) {
            font-size: 4rem;
           
          }
        }
      }

      .mobile_heroImg {
        display: none;
        @media (max-width: 1050px) {
          display: block;
          width: 75%;
          margin: auto;
          img {
            width: 100%;
          }
        }
      }

      .subtitle {
        text-align: center;
        color: $white;
        font-size: 2rem;
        @include textUnderline(60px);
      }
    }

    .heroImageContainer {
      width: 50%;
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 70%;
      }
      @media (max-width: 1050px) {
        display: none;
      }
    }

    .primaryBtn {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      color: $white;

      .btn {
        @extend .neomorphic_btn;
        font-size: 1rem;
        cursor: pointer;
        background: $primary-main;
        color: $secondary-main;
        border-radius: 30px;
        padding: 10px 41px;
      }
    }
  }

  // Section 2
  .section2 {
    width: 100%;
    padding: 0 15%;
    margin: auto;
    position: relative;
    @media (max-width: 1500px) {
      width: 100%;
      padding: 10%;
    }
    .heading {
      color: $white;
      text-align: center;
      font-size: 3rem;
      font-weight: 700;
      @include textUnderline(80px);
      @media (max-width: 411px) {
        font-size: 2.2rem;
      }
    }

    .cardContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      @media (max-width: 888px) {
        flex-direction: column;
      }
      .card {
        @extend .neomorphic_container;
        height: 450px;
        margin: 20px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        min-width: 250px;
        max-width: 350px;
        @media (max-width: 1500px) {
          margin: 20px;
        }
        @media (max-width: 888px) {
          min-width: 250px;
        }
        .cardImg {
          margin: 30px auto;
          width: 150px;
          img {
            width: 100%;
          }
        }
        .cardTitle {
          color: #fff;
          font-size: 2rem;
          text-align: center;
          font-weight: 500;
          line-height: 30px;
        }
        .cardDesc {
          text-align: center;
          line-height: 16px;
          font-size: 14px;
          font-weight: 300;
          color: #878787;
          margin-top: 1em;
        }
        .cardBtn {
          @extend .neomorphic_btn;
          // width: 100px;
          color: #fff;
          padding: 8px 40px;
          border-radius: 50px;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }

  // section 3
  .section3 {
    position: relative;
    margin-top: 130px;
    .title {
      margin: 50px 0;
      @include textUnderline(150px);
    }
    .cyberSecEle {
      width: 50%;
      margin: auto;
      @media (max-width: 411px) {
        width: 90%;
      }
      img {
        width: 100%;
      }
    }
  }

  // section 4
  .section4 {
    position: relative;

    .floatingEle5 {
      // position: absolute;
      // left: 0;
      // bottom: 0;
      left: 21px;
      bottom: -117px;
      position: absolute;
      width: 220px;
      @media (max-width: 411px) {
        width: 120px;
      }
    }
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      text-align: center;
      color: #fff;
      font-size: 2.5rem;
      font-weight: 600;
      width: 60% !important;
      margin: auto;
      line-height: 50px;
      @media (max-width: 411px) {
        font-size: 1.5rem;
        line-height: 1.7rem;
      }
    }
    .subtitle {
      color: $secondary-text;
      text-align: center;
      font-size: 1.3rem;
      margin-top: 15px;
      @media (max-width: 411px) {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
    .beginBtn {
      @extend .neomorphic_btn;
      color: #fff;
      padding: 10px 50px;
      border-radius: 20px;
      margin-top: 80px;
      cursor: pointer;
    }
  }

  .section5 {
    position: relative;
    margin-top: 225px;
    margin-bottom: 225px;
    .title {
      @include textUnderline(100px);
    }
    .carousel {
      max-width: 988px;
      margin: 50px auto;
      width: 95%;
      height: 120px;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 200px;
        height: 100%;
        position: absolute;
        @media (max-width: 700px) {
          display: none;
          opacity: 0;
          visibility: hidden;
        }
      }
      &::before {
        background-image: linear-gradient(
          to left,
          rgba(255, 255, 255, 0) 0,
          #232537 100%
        );
        z-index: 1;
      }
      &::after {
        background-image: linear-gradient(
          to left,
          #232537 0,
          rgba(255, 255, 255, 0) 100%
        );
        right: 0;
      }
      .clientBanner {
        height: 100%;
        display: flex;
        align-items: center;
        animation: slide 40s linear infinite;
        img {
          max-height: 100%;
          margin: 0 50px;
          transform: scale(1.5);
          background-color: white;
        }
      }
    }
  }

  .section6 {
    position: relative;
    margin-top: 300px;
    margin-bottom: 500px;
    width: 70%;
    margin: auto;
    .sectionTitle {
      @include textUnderline(100px);
    }
    .showMore {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      .btn {
        @extend .neomorphic_btn;
        color: #fff;
        padding: 8px 40px;
        border-radius: 50px;
        margin: auto;
        cursor: pointer;
        font-size: 1rem;
      }
    }
    .services {
      margin-top: 50px;
      .service {
        display: flex;
        margin: 80px 0;
        @media (max-width: 1050px) {
          flex-direction: column;
        }
        &.reverse {
          flex-direction: row-reverse;
          @media (max-width: 1050px) {
            flex-direction: column;
          }
          .serviceImg {
            display: flex;
            justify-content: flex-end;
            @media (max-width: 1050px) {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 20px;
            }
            img {
              width: 60%;
            }
          }
        }
        .serviceImg {
          width: 50%;
          @media (max-width: 1050px) {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }
          img {
            width: 60%;
          }
        }
        .content {
          width: 50%;
          @media (max-width: 1050px) {
            width: 100%;
          }
          .title {
            color: $secondary-main;
            font-size: 1.7rem;
            font-weight: 500;
          }
          .desc {
            color: $secondary-text;
          }
          .serviceBtn {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .btn {
              @extend .neomorphic_btn;
              color: #fff;
              padding: 8px 30px;
              border-radius: 50px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.customRadio {
  accent-color: $secondary-main;
  margin-right: 10px;
}
.surveyBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    @extend .neomorphic_btn;
    color: #fff;
    padding: 8px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 20px;
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
