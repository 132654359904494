@import '../../../palette.scss';
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');
.aboutContainer {
  .section1 {
    .title {
      font-size: 4rem;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      @media (max-width: 652px) {
        font-size: 2.2rem;
      }
      img {
        width: 200px;
        @media (max-width: 411px) {
          width: 100px;
        }
        &.leftFloatingEle {
          transform: rotate(180deg);
          position: relative;
          top: -15px;
        }
      }
    }
    .desc {
      font-size: 1.4rem;
      text-align: center;
      font-family:  Changa !important;
      line-height: 1.6;
      width: 60%;
      margin: 80px auto;
      margin-bottom: 20px;
      color: #fff;
    }
    .mission {
      .title {
        color: $secondary-main;
        text-align: center;
        font-size: 1.7rem;
        font-weight: 700;
        margin-top: 50px;
      }
      .desc {
        margin: 10px auto;
        color: $secondary-text;
        font-size: 1.3rem;
        color: #fff;
      }
    }
    .whyChoose {
      width: 80%;
      margin: auto;
      .title {
        color: $secondary-main;
        text-align: center;
        font-size: 1.7rem;
        margin-top:100px;
        font-weight: 700;
      }
      ul {
        margin: 10px auto;

        li {
          color: #fff;
        }
      }
    }
  }

  .section2 {
    width: 80%;
    margin: auto;
    .title {
      color: $secondary-main;
      text-align: center;
      font-size: 1.7rem;
      font-weight: 700;
      margin-top: 60px;
    }
    .btns {
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      .btn {
        @extend .neomorphic_btn;
        color: #fff;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px;
        border-radius: 60px;
        line-height: 0;
        @media (max-width: 767px) {
          justify-content: center;
          margin: 30px auto;
        }
        @media (max-width: 411px) {
          margin: 5px;
          width: 40px;
          height: 40px;
        }
        &.pressed {
          @extend .neomorphic_btn_press;
          color: $secondary-main;
        }
      }
    }
    .detailBox {
      display: flex;
      @media (max-width: 411px) {
        flex-direction: column;
      }
      .imgbox {
        width: 50%;
        @media (max-width: 411px) {
          width: 100%;
          margin: 20px auto;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60%;
          @media (max-width: 411px) {
            width: 100%;
          }
        }
      }
      .content {
        .title {
          font-size: 1.6rem;
          font-weight: 500;
          color: $secondary-main;
        }
        .info {
          color: $secondary-text;
        }
      }
    }
  }

  .section3 {
    margin-top: 100px;
    margin-bottom: 50px;

    .title {
      color: $secondary-main;
      text-align: center;
      font-size: 1.7rem;
      font-weight: 700;
    }
    .teamContainer {
      display: flex;
      justify-content: center;
      @media (max-width: 411px) {
        flex-direction: column;
        align-items: center;
      }
      .personCard {
        width: 250px;
        height: 300px;
        margin: 20px;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        .personImg {
          width: 250px;
          height: 300px;
          object-fit: cover;
        }
        .personDetail {
          position: absolute;
          top: 130%;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.747);
          transition: 0.5s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .name {
            color: #fff;
            font-size: 1.2rem;
            font-weight: 500;
          }
          .designation {
            color: rgb(194, 194, 194);
          }
          .links {
            margin-top: 30px;
            img {
              width: 16px;
              height: 16px;
              cursor: pointer;
              margin: 5px;
            }
          }
        }
        &:hover .personDetail {
          top: 0%;
        }
      }
    }
  }
}
