@import '../../../palette.scss';

.servicesContainer {
  .section1 {
    .title {
      font-size: 4rem;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      @media (max-width: 411px) {
        font-size: 2.2rem;
      }
      img {
        width: 200px;
        @media (max-width: 411px) {
          width: 100px;
        }
        &.leftFloatingEle {
          transform: rotate(180deg);
          position: relative;
          top: -15px;
        }
      }
    }
    .btnContainer {
      text-align: center;
      margin-top: 25px;
      width: 100%;
      margin-bottom: 35%;
      .btn {
        @extend .neomorphic_btn;
        padding: 8px 30px;
        display: inline-block;
        border-radius: 20px;
        font-size: 0.9rem;
        color: #fff;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }

  .section2 {
    .serviceBtnContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 80%;
      margin: 100px auto;
      .btns {
        @extend .neomorphic_btn;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin: 10px;
        border-radius: 20px;
        cursor: pointer;
        text-align: center;
        @media (max-width: 411px) {
          font-size: 0.8rem;
        }
        &.selected {
          color: $secondary-main;
        }
      }
    }
    .descService {
      width: 80%;
      margin: auto;
      text-align: center;
      color: rgb(184, 184, 184);
      font-size: 18px;
    }
  }

  .section3 {
    width: 70%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 1130px) {
      width: 90%;
    }
    @media (max-width: 870px) {
      width: 70%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
    }

    .card {
      @extend .neomorphic_container;
      width: 500px;
      padding: 20px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      @media (max-width: 1560px) {
        width: 450px;
      }
      @media (max-width: 1411px) {
        width: 350px;
      }
      @media (max-width: 1411px) {
        width: 350px;
      }
      @media (max-width: 870px) {
        width: 90%;
      }
      .title {
        color: $secondary-main;
        font-size: 1.3rem;
        font-weight: 700;
      }
      .image {
        width: 100%;
        img {
          width: 100%;
          height: 218px;
          object-fit: cover;
        }
      }
      .content {
        color: $secondary-text;
        margin: 20px 0;
        text-align: justify;
      }
      .btnContainer {
        display: flex;
        justify-content: flex-end;
        display: flex;
        /* flex: 1; */
        margin-top: auto;
        .btn {
          padding: 8px 25px;
          @extend .neomorphic_btn;
          font-size: 0.8rem;
          border-radius: 20px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}

.serviceModalContainer {
  max-width: 60vw;
  padding: 20px;
  align-items: center;
  @media (max-width: 825px) {
    max-width: 100%;
  }
  .titleContainer {
    display: flex;
    justify-content: space-between;
    .modalTitle {
      color: $secondary-main;
      font-weight: 600;
      font-size: 1.2rem;
    }
    .cancleBtn {
      cursor: pointer;
    }
  }
  .subContent {
    .title {
      color: #fff;
      font-weight: 500;
      font-size: 1.2rem;
    
    }
    .desc {
      color: $secondary-text;
      line-height: 1rem;
    }
  }
  .formContainer {
    .inputContainer {
      margin-top: 20px;

      label {
        color: #fff;
      }
      input {
        border: none;
        outline: none;
        background-color: transparent;
        border-bottom: 1px solid $secondary-text;
        width: 100%;
        color: #fff;
      }
    }
  }
  .btnContainer {
    margin-top: 25px;
    
    .btn {
      @extend .neomorphic_btn;
      padding: 8px 30px;
      display: inline-block;
      border-radius: 20px;
      font-size: 0.9rem;
      color: #fff;
      margin-left: auto;
      cursor: pointer;
    }
  }
}

