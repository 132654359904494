@import '../../../palette.scss';

.footerContainer {
  background-color: $footer;
  padding: 0px 100px;
  @media (max-width: 1050px) {
    padding: 0px 10px;
  }
  .companyInfo {
    display: flex;
    justify-content: space-between;
    padding: 90px 50px;
    @media (max-width: 1050px) {
      flex-direction: column;
      margin: 20px 0;
    }

    .companyContainer {
      @media (max-width: 1050px) {
        margin: 20px 0;
      }
      @media (max-width: 700px) {
        margin: 20px 0;
      }
      .title {
        color: $secondary-main;
        font-weight: 600;
        font-size: 1.2rem;
      }
      .link {
        cursor: pointer;
        color: #fff;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .contactInfoContainer {
      @media (max-width: 1050px) {
        margin: 20px 0;
      }
      .title {
        color: $secondary-main;
        font-weight: 600;
        font-size: 1.2rem;
      }
      .mail {
        display: flex;
        color: #fff;
        margin-top: 15px;

        img {
          width: 25px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
      .call {
        display: flex;
        align-items: center;
        color: #fff;
        margin-top: 15px;

        img {
          width: 25px;
          margin-right: 10px;
        }
      }
      .address {
        display: flex;
        align-items: center;
        color: #fff;
        margin-top: 15px;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    .socialContainer {
      .title {
        color: $secondary-main;
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
      .icons {
        color: #fff;
        display: flex;
        align-items: center;
      }
      @media (max-width: 1050px) {
        margin: 20px 0;
      }

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }
  .copyRight {
    border-top: 1px solid #fff;
    padding: 10px;
    color: #fff;
  }
  .fcontent {
    padding: 10px;
    color: #fff;
  }
  .underline {
    text-decoration: underline;
  }
  a, a:link, a:visited, a:focus, a:hover, a:active{
    color:white;
    text-decoration:none;
    cursor: crosshair;
  }
}

