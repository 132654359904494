@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@300;400;500;700;800&display=swap');
@import 'palette.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'changa';
  text-decoration: none;
}

a {
  text-decoration: none;
}

body {
  background-color: #232537;
  @media (max-width: 441px) {
    font-size: 14px;
  }
}
