@import '../../../palette.scss';

.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000000;
  .logo {
    min-width: 500px;
    color: #fff;
    font-weight: 700;
    font-size: 1.2rem;
    display: flex;
    transform: scale(1.3);
    align-items: center;
    margin-left: 52px;
    margin-top: 14px;
    margin-bottom: 43px;
    img {
      width:250px;
      margin-top: -60px;
    }
  }
  .navs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 40px;
    margin-top: -10px;
    @media (max-width: 1000px) {
      display: none;
    }
    a {
      text-decoration: none;
    }
    .nav {
      margin: 10px;
      padding: 5px 30px;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      margin-top: -60px;
    }

    .activeNav {
      @extend .neomorphic_container;
    }
  }
  .bars {
    padding: 10px;
    margin-right: 20px;
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }
  .mobileNavs {
    position: absolute;
    width: 100%;
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: $primary-main;
    .nav {
      cursor: pointer;
      padding: 10px;
      color: #fff;
    }
  }
}
