@import '../../../palette.scss';

.mainTitleText {
  position: relative;

  .backText {
    position: absolute;
    color: $dark-text;
    font-size: 8rem;
    font-weight: 800;
    text-align: center;
    opacity: 0.18;
    text-shadow: 0px -4px 4px #000000b8;
    top: -40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -10;
    line-height: 1;
    white-space: nowrap;
  }


  .taglineText {
    position: relative;
    color: $white;
    font-size: 5rem;
    font-weight: 800;
    text-align: center;
    line-height: 1;
  }
}
@media only screen and (max-width: 768px) {
  .backText  {
    display:none;
  }
}