@import '../../../palette.scss';

.coursesContainer {
  .section1 {
    .title {
      font-size: 4rem;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      @media (max-width: 411px) {
        font-size: 2.2rem;
      }
      img {
        width: 200px;
        @media (max-width: 411px) {
          width: 100px;
        }
        &.leftFloatingEle {
          transform: rotate(180deg);
          position: relative;
          top: -15px;
        }
      }
    }
    .couseInfo {
      color: $secondary-text;
      width: 70%;
      margin: auto;
      @media (max-width: 411px) {
        width: 90%;
      }
      ul {
        margin-left: 20px;
      }
    }
  }
  .saperator {
    width: 80%;
    margin: 90px auto;
    border: 1px solid rgb(94, 94, 94);
  }

  .section2 {
    margin-top: 300px;
    margin-bottom: 500px;
    width: 70%;
    margin: auto;
    @media (max-width: 411px) {
      width: 90%;
    }
    .mainTitle {
      color: $secondary-main;
      font-size: 2rem;
      text-align: center;
      font-weight: 700;
      img {
        width: 300px;
      }
    }
    .desc {
      color: $secondary-text;
    }
    .contentBtn {
      @extend .neomorphic_btn;
      color: #fff;
      padding: 5px 25px;
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      margin-top: 20px;
    }
    .courses {
      margin-top: 50px;
      .course {
        display: flex;
        margin: 80px 0;
        @media (max-width: 600px) {
          flex-direction: column;
        }
        &.reverse {
          flex-direction: row-reverse;
          @media (max-width: 600px) {
            flex-direction: column;
          }
          .serviceImg {
            display: flex;
            justify-content: flex-end;
            @media (max-width: 600px) {
              display: flex;
              justify-content: center;
            }
            img {
              width: 80%;
              @media (max-width: 800px) {
                width: 80%;
              }
            }
          }
        }
        .serviceImg {
          width: 30%;
          @media (max-width: 600px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          img {
            width: 80%;
            object-fit: contain;

            @media (max-width: 800px) {
              width: 80%;
            }
          }
        }
        .content {
          width: 70%;
          @media (max-width: 800px) {
            width: 100%;
          }
          .title {
            color: $secondary-main;
            font-size: 1.7rem;
            font-weight: 500;
          }
          .desc {
            color: $secondary-text;
            font-size: 0.9rem;
          }
          .duration {
            margin-top: 10px;
            color: $secondary-text;
            span {
              font-weight: 500;
            }
            .learn-image{
              position: relative;
              right:'300px';
              bottom:"150px";
            }
          }
          .ecCouncilContainer {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            .courseContant {
              width: 28%;
              // color: $secondary-text;
              color:#fff;
              background: #0000001f;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              margin: 10px;
              text-align: center;
              @media (max-width: 411px) {
                width: 45%;
                margin: 10px 3px;
              }
            }
          }
          .certificate {
            margin-top: 10px;
            color: $secondary-text;
          }
          .serviceBtn {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .btn {
              @extend .neomorphic_btn;
              color: #fff;
              padding: 8px 30px;
              border-radius: 50px;
              cursor: pointer;
            }
          }
        }
      }
      .btn {
        @extend .neomorphic_btn;
        color: #fff;
        padding: 8px 30px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
  }

  .section3 {
    width: 85%;
    display: flex;
    margin: 50px 0;
    justify-content: flex-end;
    position: relative;
    .floatingEle1 {
      position: absolute;
      width: 220px;
      left: 40px;
      @media (max-width: 1132px) {
        left: 0px;
        top: -120px;
        width: 120px;
      }
      @media (max-width: 650px) {
        display: none;
      }
    }

    @media (max-width: 1132px) {
      width: 95%;
      justify-content: center;
    }
    @media (max-width: 650px) {
      width: 90%;
    }
    .toRight {
      width: 25%;

      @media (max-width: 1132px) {
        width: 60%;
      }
      @media (max-width: 650px) {
        width: 80%;
      }
      .title {
        color: $secondary-main;
        font-size: 2rem;
        font-weight: 600;
      }
      .subTitle {
        color: $secondary-main;
        font-size: 1rem;
        font-weight: 500;
        opacity: 0.9;
      }
      .formContainer {
        .inputContainer {
          margin-top: 20px;
          label {
            color: $secondary-text;
          }
          input {
            border: none;
            outline: none;
            background: transparent;
            border-bottom: 1px solid $secondary-text;
            color: $secondary-text;
            width: 100%;
          }
        }
      }
      .btnContainer {
        margin-top: 20px;
        display: flex;
        .btn {
          @extend .neomorphic_btn;
          display: inline-block;
          padding: 8px 25px;
          color: #fff;
          border-radius: 20px;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
}

.icon {
  width: 20px;
    height: 20px;
    // vertical-align: -0.125em;
}