@import '../../../palette.scss';

.scrollToTop {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #232537;
  @extend .neomorphic_btn;
  img {
    height: 16px;
  }
}
