@import '../../../palette.scss';

.contactContainer {
  .section1 {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    .bg {
      width: 60%;
      @media (max-width: 450px) {
        display: none;
      }
      @media (max-width: 1132px) {
        width: 80%;
      }
      background: url('../../assets/images/contactImg.png');
      background-position: right;
      background-repeat: no-repeat;
    }
    .emptyBox {
      width: '50%';
      @media (max-width: 411px) {
        display: none;
      }
    }
    .contactFormContainer {
      width: 300px;
      align-self: center;
      margin-right: 200px;
      @media (max-width: 450px) {
        width: 80%;
        background: none;
        margin: auto;
      }
      .title {
        color: $secondary-main;
        font-size: 2rem;
        font-weight: 600;
      }
      
   
      .formContainer {
        .inputContainer {
          margin-top: 20px;
          label {
            color: $secondary-text;
          }
          input {
            border: none;
            outline: none;
            background: transparent;
            border-bottom: 1px solid $secondary-text;
            color: $secondary-text;
            width: 100%;
          }
        }
      }
      .btnContainer {
        margin-top: 20px;
        display: flex;
        .btn {
          @extend .neomorphic_btn;
          display: inline-block;
          padding: 8px 25px;
          color: #fff;
          border-radius: 20px;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }

  .section3 {
    width: 80%;
    margin: 80px auto;
    .title {
      color: $secondary-main;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 30px;
    }
   
    .container {
      display: flex;
      .innerContainer {
        img {
          width: 45px;
          height: 45px;
          margin-right: 15px;
        }
      }
      .detail {
        display: flex;
        flex-direction: column;
        span:first-child {
          color: #fff;
        }
        span:last-child {
          color: $secondary-text;
        }
      }
    }
  }
}
