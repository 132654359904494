@import '../../../palette.scss';

.card {
  @extend .neomorphic_container;
  height: 350px;
  margin: 20px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 350px;
  background-color: $primary-main;
  @media (max-width: 1500px) {
    margin: 20px;
  }
  @media (max-width: 888px) {
    min-width: 250px;
  }
  .cardImg {
    margin: 30px auto;
    width: 100px;
    img {
      width: 100%;
    }
  }
  .cardTitle {
    color: #fff;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    line-height: 30px;
  }
  .cardDesc {
    text-align: center;
    line-height: 17px;
    font-size: 15px;
    font-weight: 300;
    color: #878787;
    margin-top: 3em;
  }
  .cardBtn {
    @extend .neomorphic_btn;
    color: #fff;
    padding: 8px 40px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto auto 40px auto;
  }
}
