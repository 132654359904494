$primary-main: #232537;
$secondary-main: #ff7c1f;
$white: #ffffff;
$dark-text: #575757;
$secondary-text: #878787;
$footer: #313450;
.neomorphic_container {
  box-shadow: 8px 8px 17px #161723, -8px -8px 17px #30334b;
}

.neomorphic_btn {
  @extend .neomorphic_container;
  border: none;
  outline: none;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 3px 3px 7px #161723, -3px -3px 7px #30334b;
  }
  &:active {
    box-shadow: inset 8px 8px 16px #1c1e2d, inset -8px -8px 16px #2a2c41;
  }
}

.neomorphic_btn_press {
  box-shadow: 3px 3px 7px #161723, -3px -3px 7px #30334b;
}
